@use './settings/sizes';
@use './settings/spacing';
@import 'utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Saira+Stencil+One&display=swap');

@font-face {
  font-family: 'Global-Lynx';
  src: url('/assets/fonts/GlobalLynx/Global Linx.ttf');
  font-weight: 700;
  font-style: normal;
}

/* Color guide */
:root {
  --blue-darkest: #0e3b5e;
  --blue-dark: #005fab;
  --blue-medium: #1f71b8;
  --blue: #0090df;
  --blue-light: #5ac0ee;
  --blue-lightest: #98d6f9;

  --gray-darkest: #242424;
  --gray-dark: #605f5f;
  --gray: #a4a196;
  --gray-medium: #e6e6e6;
  --gray-light: #edf0f3;
  --gray-lightest: #f9fafc;

  --purple-dark: #3c3484;
  --purple-medium: #6c3483;
  --purple: #a0287d;
  --magenta: #c7005a;
  --green: #bcce00;
  --green-dark: #9eac00;
  --white: #ffffff;
  --black: #000000;
  --error: rgb(223, 43, 11);
  /* Box shadow */
  --box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  /* Container component */
  --container-width: #{sizes.$w-container};
  --container-max-width: #{max-w-container};

  @media (min-width: sizes.$mobile) {
    --container-max-width: #{sizes.$max-w-container};
  }

  @media (min-width: sizes.$tablet) {
    --container-max-width: #{sizes.$max-w-container};
  }

  @media (min-width: sizes.$desktop) {
    --container-max-width: #{sizes.$max-w-container};
  }

  /* Big Container component */
  --big-container-width: #{sizes.$w-big-container};
  --big-container-max-width: #{sizes.$max-w-big-container};

  @media (min-width: sizes.$mobile) {
    --big-container-max-width: #{sizes.$max-w-big-container};
  }

  @media (min-width: sizes.$tablet) {
    --big-container-max-width: #{sizes.$max-w-big-container};
  }

  @media (min-width: sizes.$desktop) {
    --big-container-max-width: #{sizes.$max-w-big-container};
  }

  /* Button component */
  --button-height: 50px;
  --button-border-radius: 5px;

  /* Inputs */
  --input-border-radius: 5px;
}

/* Reseting tailwind */
@layer base {
  * {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background: var(--blue);
    color: var(--white);
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  html {
    scroll-behavior: smooth;
    color: var(--gray-darkest);
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  p {
    line-height: 1.25;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 0;
  }
  input,
  textarea,
  select {
    border: 1px solid var(--gray);
    outline: none;
    height: 44px;
    color: var(--gray-darkest);
  }
  textarea {
    height: 60px;
  }

  input[type='checkbox'] {
    width: 12px;
    height: 12px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    &:not(:checked) {
      opacity: 0.4;
    }
    &:checked {
      opacity: 1;
    }
  }

  fieldset {
    border: 1px solid var(--gray-dark);
  }

  ::placeholder {
    color: var(--gray-medium);
  }
}

/* Components */
@layer components {
  /* Big Title */
  .text-bigTitle {
    @apply text-[35px];
  }

  @screen tablet {
    .text-bigTitle {
      @apply text-[38px];
    }
  }

  @screen desktop {
    .text-bigTitle {
      @apply text-[45px];
    }
  }

  /* Medium Title */
  .text-mediumTitle {
    @apply text-[28px];
  }

  @screen tablet {
    .text-mediumTitle {
      @apply text-[30px];
    }
  }

  @screen desktop {
    .text-mediumTitle {
      @apply text-[35px];
    }
  }

  /* Small Title */
  .text-smallTitle {
    @apply text-[24px];
  }

  @screen tablet {
    .text-smallTitle {
      @apply text-[26px];
    }
  }

  @screen desktop {
    .text-smallTitle {
      @apply text-[28px];
    }
  }

  /* Big */
  .text-big {
    @apply text-[20px];
  }

  @screen tablet {
    .text-big {
      @apply text-[22px];
    }
  }

  @screen desktop {
    .text-big {
      @apply text-[25px];
    }
  }

  /* Medium  */
  .text-medium {
    @apply text-[18px];
  }

  @screen tablet {
    .text-medium {
      @apply text-[20px];
    }
  }

  @screen desktop {
    .text-medium {
      @apply text-[20px];
    }
  }

  /* Regular  */
  .text-regular {
    @apply text-[16px];
  }

  @screen tablet {
    .text-regular {
      @apply text-[16px];
    }
  }

  @screen desktop {
    .text-regular {
      @apply text-[16px];
    }
  }

  /* Small  */
  .text-small {
    @apply text-[14px];
  }

  @screen tablet {
    .text-small {
      @apply text-[14px];
    }
  }

  @screen desktop {
    .text-small {
      @apply text-[14px];
    }
  }

  /* Extra Small  */
  .text-xs {
    @apply text-[10px];
  }

  @screen tablet {
    .text-xs {
      @apply text-[11px];
    }
  }

  @screen desktop {
    .text-xs {
      @apply text-[12px];
    }
  }

  .loading-effect-animation {
    z-index: 1;
    position: relative;
    overflow: hidden;
  }
  .loading-effect-animation::after {
    content: '';
    position: absolute;
    top: 0;
    left: -500px;
    width: 1000px;
    height: 100%;
    background: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation-name: loading-effect;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: inherit;
  }
  @keyframes loading-effect {
    0% {
      left: -500px;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .child-span-blue span,
  .child-span-blue strong {
    color: var(--blue);
  }
  .child-span-blue-dark span,
  .child-span-blue-dark strong {
    color: var(--blue-dark);
  }
  .child-span-blue-darkest span,
  .child-span-blue-darkest strong {
    color: var(--blue-darkest);
  }
  .child-span-blue-light > span {
    color: var(--blue-light);
    font-weight: 800;
  }

  .decoration-blue-underline {
    position: relative;
    left: 0;
    width: 300px;
    max-width: 90% !important;
    border: none;
    border-top: 6px solid var(--blue-light);
    display: flex;
    align-items: center;
    margin: 1rem 0px;
    // Before element will be a dot at end of the after line
    &::before {
      content: '';
      position: absolute;
      left: calc(100% + 5px);
      transform: translateY(-3px);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--blue-light);
    }
  }
  .decoration-center-blue-underline {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 1000px;
    background-color: var(--blue-light);
    margin: 1rem auto;
    // Before element will be a dot at center of the after line
    &::before {
      content: '';
      position: absolute;
      width: 140px;
      height: 6px;
      right: calc(100% + 5px);
      background: var(--blue-light);
    }
    &::after {
      content: '';
      position: absolute;
      width: 140px;
      height: 6px;
      left: calc(100% + 5px);
      background: var(--blue-light);
    }
  }
  .blue-line {
    position: relative;
    min-height: 6px;
    width: 300px;
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      border: 1px solid transparent;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--blue-light);
    }
  }
  .decoration-dark-blue-pill {
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      width: 300%;
      height: calc(100% + 10px);
      right: -50px;
      top: -5px;
      background-color: var(--blue-darkest);
      border-radius: 1000px;
      z-index: -1;
    }
  }
  .decoration-center-dark-blue-pill {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    &::before {
      content: '';
      position: absolute;
      width: 300%;
      height: calc(100% + 10px);
      right: -50px;
      top: -5px;
      background-color: var(--blue-darkest);
      border-radius: 1000px;
      z-index: -1;
    }
  }

  .bg-blue-dark-degree-banner {
    background: linear-gradient(to top, rgb(2, 24, 40, 0.9) 0%, rgb(13, 56, 88, 0.1) 100%);
  }
  .bg-white-to-gray {
    background: linear-gradient(to right, #fff 50%, var(--gray-light) 50%);
  }
  .bg-gray-to-white {
    background: linear-gradient(to right, var(--gray-light) 50%, #fff 50%);
  }
  .bg-gray-degree {
    background: rgb(249, 250, 252);
    background: linear-gradient(180deg, rgba(249, 250, 252, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
  .bg-blue-degree {
    background: rgb(21, 60, 93);
    background: linear-gradient(0deg, rgba(21, 60, 93, 1) 0%, rgba(37, 95, 146, 1) 100%);
  }
  .bg-blue-medium-degree {
    background: rgb(6, 79, 120);
    background: linear-gradient(0deg, rgba(6, 79, 120, 1) 0%, rgba(8, 102, 147, 1) 100%);
  }
  .bg-blue-light-degree {
    background: rgb(8, 102, 147);
    background: linear-gradient(0deg, rgba(8, 102, 147, 1) 0%, rgba(57, 141, 190, 1) 100%);
  }
  .bg-blue-dark-degree {
    background: #0c3554;
    background: linear-gradient(to top, #031b2c 10%, #0c3554 100%);
  }

  .bg-blue-darkest-to-b {
    background: #0d3858;
    background: linear-gradient(to bottom, #021828 10%, #0d3858 100%);
  }
  .bg-blue-darkest-to-t {
    background: #0d3858;
    background: linear-gradient(to top, #021828 10%, #0d3858 100%);
  }
  .bg-dark-gray-to-b {
    background: #4a4a4a;
    background: linear-gradient(to bottom, #272727 33%, #4b4b4b 100%);
  }
  .bg-dark-gray-to-t {
    background: #4a4a4a;
    background: linear-gradient(to top, #272727 33%, #4b4b4b 100%);
  }

  .select {
    height: 45px;
  }

  .animate-download {
    // animation: download 1s ease-in-out forwards;
    animation-name: download;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  @keyframes download {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .socialLink {
    span {
      transition: 0.5s;
      border-radius: 1000px;
      vertical-align: middle;
      @apply text-white bg-blue-darkest;
      &:hover {
        @apply bg-blue-light;
      }
      svg {
        width: 60%;
        height: 60%;
      }
    }
  }
  .bg-tab-title {
    z-index: 1;
    position: relative;
    background-color: var(--blue-darkest);
  }
  .bg-tab-title::after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(100%);
    width: 10px;
    height: calc(100%);
    z-index: 0;
    background: linear-gradient(220deg, transparent 20%, var(--blue-darkest) 20.1%);
  }
}

/* Utilities */
@layer utilities {
  .text-4xs {
    @apply text-[4px] xl:text-[6px];
    @apply 2xl:text-[8px];
  }
  .text-3xs {
    @apply text-[6px] xl:text-[8px];
    @apply 2xl:text-[10px];
  }
  .text-2xs {
    @apply text-[8px] xl:text-[10px];
    @apply 2xl:text-[12px];
  }
  .text-xs {
    @apply text-[10px] xl:text-[12px];
    @apply 2xl:text-[14px];
  }
  .text-sm {
    @apply text-[14px] 2xl:text-[16px];
  }
  .text-base {
    @apply text-[16px] 2xl:text-[18px];
  }
  .text-lg {
    @apply text-[16px] md:text-[18px] 2xl:text-[20px];
  }
  .text-xl {
    @apply text-[18px] md:text-[20px] 2xl:text-[24px];
  }
  .text-2xl {
    @apply text-[20px] md:text-[22px] lg:text-[26px];
  }
  .text-3xl {
    @apply text-[24px] md:text-[26px] lg:text-[30px] 2xl:text-[32px];
  }
  .text-4xl {
    @apply text-[32px] md:text-[34px] lg:text-[38px] 2xl:text-[42px];
  }
  .text-5xl {
    @apply text-[36px] md:text-[38px] lg:text-[42px] 2xl:text-[52px];
  }
  .text-6xl {
    @apply text-[60px] md:text-[64px] lg:text-[66px] 2xl:text-[70px];
  }
  .text-7xl {
    @apply text-[65px] md:text-[68px] lg:text-[70px] 2xl:text-[75px];
  }
  .text-8xl {
    @apply text-[80px] md:text-[84px] lg:text-[88px] 2xl:text-[90px];
  }
  .text-9xl {
    @apply text-[100px] md:text-[104px] lg:text-[108px] 2xl:text-[110px];
  }

  .link-primary {
    @apply text-blue-dark underline decoration-transparent hover:decoration-blue-dark underline-offset-2 duration-300;
  }
  .leading-1 {
    line-height: 1 !important;
  }
}
/* Hr */
.blueLine {
  margin-top: 0.5rem;
  width: 300px;
  border: none;
  border-top: 4px solid var(--blue-light);
}

.grayLine {
  margin: 1.5rem auto;
  width: 100%;
  border: none;
  border-top: 1px solid var(--gray-medium);
}

.blueDash {
  content: '';
  display: inline-block;
  width: 7.5px;
  margin-bottom: 6px;
  height: 2.5px;
  background-color: var(--blue-light);
}
.fade-appear {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.88, 0.1, 0.62, 0.97) forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
