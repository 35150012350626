.no-size {
  width: 0;
  height: 0;
}

.flex-full-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vetical-center {
  display: flex;
  align-items: center;
}

.horizonal-center {
  display: flex;
  justify-content: center;
}
